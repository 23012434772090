<template>
  <default-layout>
    <div class="back-link">
      <button v-button:to-list @click="returnToList"/>
    </div>
    <section>
      <h2 class="section-title"><span class="icon">badge</span>事務局管理者</h2>
      <div class="row">
        <div class="table-responsive">
          <table class="table table-bordered" v-table:info v-cols="[30, 20, 20, 30]">
            <tbody>
              <tr>
                <th>メールアドレス</th>
                <td>{{user.email}}</td>
                <th>名前</th>
                <td>{{user.name}}</td>
              </tr>
              <tr>
                <th>ステータス</th>
                <td colspan="3">
                  <span v-if="user.locked">アカウントロック中<a @click="unlockAccount" class="ml-1">(ロック解除)</a></span>
                  <span v-else-if="user.registered">アカウント登録済</span>
                  <span v-else-if="user.tokenExpirationTime != null">
                    アカウント登録メール送信済み / 有効期限：{{user.tokenExpirationTime | datetime}}
                    <span v-if="user.tokenExpired">（期限切れ）</span>
                  </span>
                  <span v-else>アカウント登録メール未送信</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-center" v-if="user.email">
            <button v-button:edit :userAccountId="userAccountId" @click="editUser"/>
            <button v-if="!user.registered" v-button:primary @click="sentRegistrationMail">アカウント登録メールを送信する</button>
            <button v-if="!user.registered || sites.length == 0" v-button:delete @click="deleteUser"/>
          </div>
        </div>
      </div>
    </section>

    <modal ref="userModal" title="管理者情報編集">
      <user-modal :userAccountId="userAccountId" @close="closeUserModal"/>
    </modal>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";
import UserModal from "./UserModal";

export default {
  components: {UserModal},
  data() {
    return {
      user: {},
      sites: [],
      siteId: null,
      siteCode: '',
      error: null
    }
  },
  computed: {
    userAccountId: vm => vm.$route.query.userAccountId
  },
  mounted() {
    this.refreshUser();
  },
  methods: {
    refreshUser() {
      this.error = null;
      return Service.fetchUser(this.userAccountId).then(user => {
        this.user = user;
      });
    },
    unlockAccount() {
      Service.unlockAccount(this.userAccountId).then(() =>{
          this.refreshUser();
      }).catch(error => {
      this.error = error;
      });
    },
    editUser() {
      this.$refs.userModal.open();
    },
    closeUserModal(refresh) {
      if (refresh) {
        this.refreshUser().then(() => this.$refs.userModal.close());
      } else {
        this.$refs.userModal.close();
      }
    },
    deleteUser() {
      if (!confirm('削除してもよろしいですか？')) return;
      Service.deleteUser(this.userAccountId).then(() => {
        this.returnToList();
      }).catch(error => {
        this.error = error;
      })
    },
    returnToList() {
      this.$move('./index', null, true);
    },
    sentRegistrationMail() {
      if (!confirm('アカウント登録メールを送信してよろしいですか？')) return;
      Service.sendRegistrationMail(this.userAccountId).then(() => {
        this.refreshUser();
      }).catch(error => {
        this.error = error;
      })
    }
  }
};
</script>
