<template>
  <default-layout>
    <div class="back-link">
      <button v-button:to-list @click="returnToList"/>
    </div>
    <section>
      <h2 class="section-title"><span class="icon">badge</span>試験管理者</h2>
      <div class="row">
        <div class="table-responsive">
          <table class="table table-bordered" v-table:info v-cols="[30, 20, 20, 30]">
            <tbody>
              <tr>
                <th>メールアドレス</th>
                <td>{{user.email}}</td>
                <th>名前</th>
                <td>{{user.name}}</td>
              </tr>
              <tr>
                <th>ステータス</th>
                <td colspan="3">
                  <span v-if="user.locked">アカウントロック中<a @click="unlockAccount" class="ml-1">(ロック解除)</a></span>
                  <span v-else-if="user.registered">アカウント登録済</span>
                  <span v-else-if="user.tokenExpirationTime != null">
                    アカウント登録メール送信済み / 有効期限：{{user.tokenExpirationTime | datetime}}
                    <span v-if="user.tokenExpired">（期限切れ）</span>
                  </span>
                  <span v-else>アカウント登録メール未送信</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-center" v-if="user.email">
            <button v-button:edit :userAccountId="userAccountId" @click="editTestAdmin"/>
            <button v-if="!user.registered" v-button:primary @click="sentRegistrationMail">アカウント登録メールを送信する</button>
            <button v-if="!user.registered || sites.length == 0" v-button:delete @click="deleteTestAdmin"/>
          </div>
        </div>
      </div>
    </section>
    <section>
      <h2 class="section-title"><span class="icon">corporate_fare</span>担当教室</h2>
      <div class="row">
        <div class="table-responsive">
          <table class="table table-bordered table-striped" v-table:info v-cols="[20, 35, 15, 15, 15]">
            <tbody>
              <tr>
                <th>教室コード</th>
                <th>教室名</th>
                <th>管理者</th>
                <th>会場担当者</th>
                <th></th>
              </tr>
              <tr v-for="s in sites" v-bind:key="s.siteId">
                <td class="text-center">{{s.code}}</td>
                <td>{{s.name}}</td>
                <td class="text-center"><span class="icon" v-if="!s.inCharge">circle</span><span class="icon" v-else>remove</span></td>
                <td class="text-center"><span class="icon" v-if="s.inCharge">circle</span><span class="icon" v-else>remove</span></td>
                <td class="text-center">
                  <button v-button:delete @click="deleteSite(s.siteId)"/>
                </td>
              </tr>
              <tr>
                <td><input-text v-model="siteCode" :error="$error('siteCode')"/></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-center">
                  <button v-button:add @click="addSite"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <modal ref="testAdminModal" title="管理者情報編集">
      <test-admin-modal :userAccountId="userAccountId" @close="closeTestAdminModal"/>
    </modal>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";
import TestAdminModal from "./TestAdminModal";

export default {
  components: {TestAdminModal},
  data() {
    return {
      user: {},
      sites: [],
      siteId: null,
      siteCode: '',
      error: null
    }
  },
  computed: {
    userAccountId: vm => vm.$route.query.userAccountId
  },
  mounted() {
    this.refreshUser();
    this.refreshSites();
  },
  methods: {
    refreshUser() {
      this.error = null;
      return Service.fetchTestAdmin(this.userAccountId).then(user => {
        this.user = user;
      });
    },
    refreshSites() {
      this.siteCode = '';
      this.error = null;
      return Service.selectSites(this.userAccountId).then(sites => {
        this.sites = sites;
      });
    },
    unlockAccount() {
      Service.unlockAccount(this.userAccountId).then(() =>{
        this.refreshUser();
      }).catch(error => {
        this.error = error;
      });
    },
    editTestAdmin() {
      this.$refs.testAdminModal.open();
    },
    closeTestAdminModal(refresh) {
      if (refresh) {
        this.refreshUser().then(() => this.$refs.testAdminModal.close());
      } else {
        this.$refs.testAdminModal.close();
      }
    },
    deleteTestAdmin() {
      if (!confirm('削除してもよろしいですか？')) return;
      Service.deleteTestAdmin(this.userAccountId).then(() => {
        this.returnToList();
      }).catch(error => {
        this.error = error;
      })
    },
    returnToList() {
      this.$move('./index', null, true);
    },
    addSite() {
      Service.addSite(this.userAccountId, this.siteCode).then(() => {
        this.refreshSites();
      }).catch(error => {
        this.error = error;
      })
    },
    deleteSite(siteId) {
      if (!confirm('削除してもよろしいですか？')) return;
      Service.deleteSite(this.userAccountId, siteId).then(() => {
        this.refreshSites();
      }).catch(error => {
        this.error = error;
      })
    },
    sentRegistrationMail() {
      if (!confirm('アカウント登録メールを送信してよろしいですか？')) return;
      Service.sendRegistrationMail(this.userAccountId).then(() => {
        this.refreshUser();
      }).catch(error => {
        this.error = error;
      })
    }
  }
};
</script>
