<template>
  <default-layout>
    <section class="form-inline" v-if="form">
      <h2 class="section-title"><span class="icon">manage_search</span>検索条件</h2>
      <span class="form-inner">
        <span class="font-weight-bold">教室コード：</span>
        <input-text v-model="form.code" />
      </span>
      <span class="form-inner">
        <span class="font-weight-bold">教室名：</span>
        <input-text v-model="form.name" />
      </span>
      <div class="text-center pt-2">
        <button @click="sc.onSearch()" :disabled="processing" v-button:search />
      </div>
    </section>

    <section v-if="result">
      <h2 class="section-title"><span class="icon">view_list</span>教室一覧</h2>
      <div class="table-responsive">
        <div>
          <pager :search-context="sc" />
        </div>
        <table class="table table-bordered table-striped" v-cols="[20,30,40,10]">
          <tbody>
            <tr>
              <th>教室コード</th>
              <th>教室名</th>
              <th>教室担当者</th>
              <th></th>
            </tr>
            <tr v-for="record in result.records" :key="record.siteId">
              <td class="text-center">{{record.code}}</td>
              <td class="text-left">{{record.name}}</td>
              <td>
                <span v-if="record.taEmail !== null">{{record.taName}}&lt;{{record.taEmail}}&gt;</span>
                <span v-else>未登録</span>
              </td>
              <td class="text-center"><button v-button:detail @click="$move('detail', { siteId: record.siteId }, $event)"></button></td>
            </tr>
          </tbody>
        </table>
        <div>
          <pager :search-context="sc" />
        </div>
      </div>
    </section>

    <section v-if="result">
      <div class="text-center">
        <button v-button:new @click="addSite()"/>
        <button v-button:bulk @click="upload()"/>
      </div>
    </section>

    <modal ref="siteModal" title="新規追加">
      <site-modal @close="closeSiteModal" />
    </modal>

    <modal ref="uploadModal" title="一括登録">
      <upload-modal @close="closeUploadModal" />
    </modal>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import {SearchContext} from '@/calico-vue/service';
import SiteModal from "./SiteModal";
import UploadModal from "./UploadModal";

export default {
  components: {SiteModal, UploadModal},
  data() {
    return {
      sc: null,
      form: false,
      result: null,
      error: null
    }
  },
  created() {
    this.sc = SearchContext.forDefault(this, {
      api: Service,
      eager: true,
    }).init();
  },
  methods: {
    addSite() {
      this.$refs.siteModal.open();
    },
    upload() {
      this.$refs.uploadModal.open();
    },
    closeSiteModal(refresh) {
      if (refresh) {
        this.sc.onSearch();
      }
      this.$refs.siteModal.close();
    },
    closeUploadModal(refresh) {
      if (refresh) {
        this.sc.onSearch();
      }
      this.$refs.uploadModal.close();
    }
  }
}
</script>
