<template>
  <default-layout>
    <div class="back-link">
      <button v-button:to-list @click="returnToList"/>
    </div>
    <section v-if="examFormGroup">
      <h2 class="section-title"><span class="icon">edit_square_outline</span>試験問題</h2>
      <div class="table-responsive">
        <table class="table table-bordered" v-cols="[20, 80]">
          <tbody>
          <tr>
            <th>試験問題コード</th>
            <td>{{examFormGroup.code}}</td>
          </tr>
          <tr>
            <th>使われている試験</th>
            <td class="text-left"><span v-for="t in examTerms" :key="t.examTermId" class="code-names">{{t.name}}</span></td>
          </tr>
          <tr>
            <th>問題データ</th>
            <td colspan="3">
              <div class="exam-form" v-for="f in examFormGroup.examForms" :key="f.examFormId">
                {{f.examGradeName}}: <a @click="openUploadModal(f.examFormId)">{{f.uploaded ? '登録済' : '未登録'}}</a>
              </div>
            </td>
          </tr>
          <tr>
            <th>正解データ</th>
            <td colspan="3" class="text-left">
              <div><a @click="openAnswerModal()">{{answer ? '登録済' : '未登録'}}</a></div>
            </td>
          </tr>
          <tr>
            <th>配点データ</th>
            <td colspan="3" class="text-left">
              <div><a @click="openConversionModal()">{{scoreConversion ? '登録済' : '未登録'}}</a></div>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="text-center">
          <button v-button:cancel @click="returnToList" />
          <button v-button:delete v-if="deletable" @click="deleteExamFormGroup" />
        </div>
      </div>
    </section>

    <modal ref="uploadModal" title="問題データ登録">
      <upload-modal :exam-form-id="examFormId" @close="closeUploadModal"/>
    </modal>
    <modal ref="uploadAnswerModal" title="正解データ登録">
      <upload-answer-modal :exam-form-group-id="this.examFormGroupId" @close="closeAnswerModal"/>
    </modal>
    <modal ref="uploadConversionModal" title="配点データ登録">
      <upload-conversion-modal :exam-form-group-id="this.examFormGroupId" @close="closeConversionModal"/>
    </modal>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import UploadModal from "./UploadModal";
import UploadAnswerModal from "./UploadAnswerModal";
import UploadConversionModal from "./UploadConversionModal.vue";

export default {
  components: {UploadModal, UploadAnswerModal, UploadConversionModal},
  data() {
    return {
      examFormGroup: null,
      examTerms: null,
      deletable: false,
      answer: false,
      scoreConversion: false,
      examFormId: null,
      error: null
    }
  },
  computed: {
    examFormGroupId: vm => vm.$route.query.examFormGroupId
  },
  created() {
    this.refreshExamFormGroup();
  },
  methods: {
    returnToList() {
      this.$move('index', null, true);
    },
    refreshExamFormGroup() {
      Service.fetchExamFormGroup(this.examFormGroupId).then(data => {
        this.examFormGroup = data.examFormGroup;
        this.examTerms = data.examTerms;
        this.answer = data.answer;
        this.scoreConversion = data.scoreConversion;
        this.deletable = data.deletable;
      }).catch(error => this.error = error);
    },
    openUploadModal(examFormId) {
      this.examFormId = examFormId;
      this.$refs.uploadModal.open();
    },
    closeUploadModal(refresh) {
      if (refresh) {
        this.refreshExamFormGroup();
      }
      this.$refs.uploadModal.close();
    },
    openAnswerModal() {
      this.$refs.uploadAnswerModal.open();
    },
    closeAnswerModal(refresh) {
      if (refresh) {
        this.refreshExamFormGroup();
      }
      this.$refs.uploadAnswerModal.close();
    },
    openConversionModal() {
      this.$refs.uploadConversionModal.open();
    },
    closeConversionModal(refresh) {
      if (refresh) {
        this.refreshExamFormGroup();
      }
      this.$refs.uploadConversionModal.close();
    },
    deleteExamFormGroup() {
      if (!confirm('削除してもよろしいですか？')) return;
      Service.deleteExamFormGroup(this.examFormGroupId).then(() => {
        this.returnToList();
      }).catch(error => this.error = error);
    },
  }
};
</script>

<style scoped>
.exam-form {
  display: inline;
  margin-right: 15px;
}
.code-names {
  margin-right: 10px;
}
</style>
