<template>
  <default-layout>
    <div class="back-link">
      <button v-button:to-list @click="returnToList"/>
    </div>
    <section v-if="examTerm">
      <h2 class="section-title"><span class="icon">edit_square</span>試験</h2>
      <div class="table-responsive">
        <table class="table table-bordered" v-cols="[10,15,15,40,10,10]">
          <tbody>
          <tr>
            <th>試験名</th>
            <td>{{examTerm.name}}</td>
            <th>実施期間</th>
            <td>{{examTerm.startTime | datetime}} 〜 {{examTerm.endTime | datetime}}</td>
            <th>採点</th>
            <td>{{examTerm.marked ? "済" : "未"}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center" v-if="examTerm.showResults">
        <button @click="downloadAllScoreReports" v-button:download>スコアレポートダウンロード（全教室分）</button>
        <button @click="downloadAllCertificates" v-button:download>合格証ダウンロード（全教室分）</button>
      </div>
    </section>

    <section class="form-inline" v-if="form">
      <h2 class="section-title"><span class="icon">manage_search</span>実施教室 検索条件</h2>
      <span class="form-inner">
        <span class="font-weight-bold">教室コード：</span>
        <input-text v-model="form.code" />
      </span>
      <span class="form-inner">
        <span class="font-weight-bold">教室名：</span>
        <input-text v-model="form.name" />
      </span>
      <div class="text-center pt-2">
        <button @click="sc.onSearch()" :disabled="processing" v-button:search />
      </div>
    </section>

    <section v-if="result">
      <h2 class="section-title"><span class="icon">view_list</span>実施教室一覧</h2>
      <div class="table-responsive">
        <div>
          <pager :search-context="sc" />
        </div>
        <table class="table table-bordered table-striped" v-cols="[20,65,15]">
          <tbody>
          <tr>
            <th>教室コード</th>
            <th>教室名</th>
            <th></th>
          </tr>
          <tr v-for="record in result.records" :key="record.siteId">
            <td class="text-center">{{record.code}}</td>
            <td class="text-left">{{record.name}}</td>
            <td class="text-center"><button v-button:detail @click="$move('examinees', { examTermId, siteId: record.siteId }, $event)"></button></td>
          </tr>
          </tbody>
        </table>
        <div>
          <pager :search-context="sc" />
        </div>
      </div>
    </section>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import {SearchContext} from "@/calico-vue/service";

export default {
  data() {
    return {
      examTerm: null,
      form: null,
      result: null,
      error: null,
    }
  },
  computed: {
    examTermId: vm => vm.$route.query.examTermId,
  },
  created() {
    Service.fetchExamTerm(this.examTermId)
        .then(examTerm => this.examTerm = examTerm)
        .catch(error => this.error = error);
    this.sc = SearchContext.forDefault(this, {
      api: {
        getSearchForm: () => Service.getSiteSearchForm().then(form => {
          form.examTermId = this.examTermId
          return form;
        }),
        search: Service.siteSearch
      },
      eager: true,
    }).init();
  },
  methods: {
    returnToList() {
      this.$move('index', null, true);
    },
    downloadAllScoreReports() {
      window.open("/download/endpoint/org/exam/download_all_score_reports?examTermId=" + this.examTermId, "_blank");
    },
    downloadAllCertificates() {
      window.open("/download/endpoint/org/exam/download_all_certificates?examTermId=" + this.examTermId, "_blank");
    }
  }
};
</script>
