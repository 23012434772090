<template>
  <form v-form class="form-inline" v-if="site">
    <div class="table-responsive">
      <table class="table table-bordered" v-table:info v-cols="[40, 60]">
        <tbody>
          <tr>
            <th v-req="!site.siteId">教室コード</th>
            <td v-if="site.siteId">{{site.siteCode}}</td>
            <td v-else><input-text v-model="site.siteCode" :error="$error('siteCode')"/></td>
          </tr>
          <tr>
            <th v-req>教室名</th>
            <td><input-text v-model="site.siteName" :error="$error('siteName')"/></td>
          </tr>
          <tr>
            <th v-req>教室担当者登録パスワード</th>
            <td><input-text v-model="site.registrationPassword" :error="$error('registrationPassword')"/></td>
          </tr>
        </tbody>
      </table>
      <div class="text-center">
        <button v-button:cancel @click="close" />
        <button v-button:save @click="save" :disabled="processing"/>
      </div>
    </div>
  </form>
</template>

<script>
import {Service} from "./_resource";
import {announce} from "@/calico-vue/announce/announce";

export default {
  data() {
    return {
      site: null,
      error: null
    };
  },
  props: [
    "siteId"
  ],
  mounted() {
    if (this.siteId) {
      Service.fetchSite(this.siteId).then(site => {
        this.site = site;
      });
    } else {
      this.site = {};
    }
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
    save() {
      if (this.siteId) {
        Service.updateSite(this.site).then(() => {
          announce.saved();
          this.$emit('close', true);
        }).catch(error => {
          this.error = error;
        });
      } else {
        Service.addSite(this.site).then(data => {
          announce.saved();
          this.$move('detail', {siteId: data.siteId});
        }).catch(error => {
          this.error = error;
        });
      }
    }
  }
};
</script>
