<template>
  <default-layout>
    <section v-if="result">
      <h2 class="section-title"><span class="icon">view_list</span>試験一覧</h2>
      <div class="table-responsive">
        <div>
          <pager :search-context="sc" />
        </div>
        <table class="table table-bordered table-striped" v-cols="[30,50,10,10]">
          <tbody>
          <tr>
            <th>試験名</th>
            <th>実施期間</th>
            <th>採点</th>
            <th></th>
          </tr>
          <tr v-for="record in result.records" :key="record.examTermId">
            <td class="text-left">{{record.name}}</td>
            <td class="text-center">{{record.startTime | datetime}} 〜 {{record.endTime | datetime}}</td>
            <td class="text-center">{{record.marked ? "済" : "未"}}</td>
            <td class="text-center"><button v-button:detail @click="$move('sites', { examTermId: record.examTermId })">詳細</button></td>
          </tr>
          </tbody>
        </table>
        <div>
          <pager :search-context="sc" />
        </div>
      </div>
    </section>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import {SearchContext} from '@/calico-vue/service';

export default {
  data () {
    return {
      sc: null,
      form: false,
      result: null,
      error: null,
    }
  },
  created() {
    this.sc = SearchContext.forDefault(this, {
      api: Service,
      eager: true,
    }).init();
  },
  methods: {
  }
}
</script>
