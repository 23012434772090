<template>
  <form v-form class="form-inline">
    <p>
      CSVファイルをアップロードして教室を一括登録します。<br>
      既に教室コードが存在している場合は、教室名、管理者登録パスワードが更新されます。
      <a @click="downloadSample">サンプルファイルダウンロード</a>
    </p>
    <div class="table-responsive mt-1">
      <table class="table table-bordered" v-table:info v-cols="[40, 60]">
        <tbody>
        <tr>
          <th>CSVファイル</th>
          <td><input-file v-model="media" :error="$error('media')"/></td>
        </tr>
        </tbody>
      </table>
      <div class="text-center">
        <button v-button:cancel @click="close" />
        <button v-button:success @click="upload" :disabled="processing">アップロード</button>
      </div>
    </div>
    <div class="table-responsive mt-2" v-if="csvErrors">
      <table class="table table-bordered table-striped" v-table:info v-cols="[10, 90]">
        <tbody>
          <tr>
            <th>行</th>
            <th>エラー</th>
          </tr>
          <tr v-for="e in csvErrors" v-bind:key="e.lineNumber">
            <td class="text-right">{{e.lineNumber}}</td>
            <td>
              <div v-for="line in e.errors" v-bind:key="line">{{line}}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</template>

<script>
import {Service} from "./_resource";
import {announce} from "@/calico-vue/announce/announce";
import {MediaService} from "@/calico-vue/service";

export default {
  data() {
    return {
      media: null,
      error: null,
      csvErrors: null
    };
  },
  methods: {
    downloadSample() {
      Service.downloadSample().then(data => {
        MediaService.download(data.media);
      }).catch(error => {
        this.error = error;
      });
    },
    close() {
      this.$emit('close', false);
    },
    upload() {
      Service.updateSitesInBulk(this.media).then(result => {
        if (result.errors) {
          this.csvErrors = result.errors;
        } else {
          announce.success("一括登録しました。");
          this.$emit('close', true);
        }
      }).catch(error => {
        this.error = error;
      });
    }
  }
};
</script>
