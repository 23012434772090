<template>
  <form v-form class="form-inline">
    <p>
      CSVファイルをアップロードして受験者を一括登録します。<br>
      既に受験コードが存在している場合は、受験者情報が更新されます。
    </p>
    <div class="mt-1">
      <a @click="downloadSample">&raquo;サンプルファイルダウンロード</a>
    </div>
    <div class="table-responsive mt-1">
      <table class="table table-bordered" v-table:info v-cols="[40, 60]">
        <tbody>
          <tr>
            <th>CSVファイル</th>
            <td><input-file v-model="file" :error="$error('name')"/></td>
          </tr>
        </tbody>
      </table>
      <div class="text-center">
        <button v-button:cancel @click="close" />
        <button v-button:save @click="save" :disabled="processing"/>
      </div>
    </div>
    <div class="table-responsive mt-2" v-if="csvErrors">
      <table class="table table-bordered table-striped" v-table:info v-cols="[10, 90]">
        <tbody>
          <tr>
            <th>行</th>
            <th>エラー</th>
          </tr>
          <tr v-for="e in csvErrors" v-bind:key="e.lineNumber">
            <td class="text-right">{{e.lineNumber}}</td>
            <td>
              <div v-for="line in e.errors" v-bind:key="line">{{line}}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</template>

<script>
import {Service} from "./_resource";
import {announce} from "@/calico-vue/announce/announce";
import {MediaService} from "@/calico-vue/service";

export default {
  data() {
    return {
      file: null,
      processing: false,
      csvErrors: null
    };
  },
  props: [
    "examTermId"
  ],
  methods: {
    downloadSample() {
      Service.downloadSample().then(data => {
        MediaService.download(data.media);
      }).catch(error => {
        this.error = error;
      });
    },
    close() {
      this.$emit('close', false);
    },
    save() {
      this.processing = true;
      Service.uploadForm(this.examTermId, this.file).then(result => {
        if (result.errors) {
          this.csvErrors = result.errors;
        } else {
          announce.success("一括登録しました。");
          this.$emit('close', true);
        }
      }).catch(error => {
        this.error = error;
        this.processing = false;
      });
    }
  }
};
</script>
