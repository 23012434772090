<template>
  <default-layout>
    <div class="back-link">
      <button v-button:to-list @click="returnToList"/>
    </div>
    <section>
      <h2 class="section-title"><span class="icon">corporate_fare</span>会場</h2>
      <div class="row">
        <div class="table-responsive">
          <table class="table table-bordered" v-table:info v-cols="[30,20,20,30]">
            <tbody>
            <tr>
              <th>団体コード</th>
              <td>{{site.organizationCode}}</td>
              <th>団体名</th>
              <td>{{site.organizationName}}</td>
            </tr>
            <tr>
              <th>会場コード</th>
              <td>{{site.siteCode}}</td>
              <th>会場名</th>
              <td>{{site.siteName}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <section>
      <h2 class="section-title"><span class="icon">badge</span>管理者</h2>
      <div class="row">
        <div v-if="adminUsers.length > 0" class="table-responsive">
          <table class="table table-bordered table-striped" v-table:info v-cols="[30,25,15,15,15]">
            <tbody>
            <tr>
              <th>ログインID</th>
              <th>名前</th>
              <th>管理者</th>
              <th>教室担当者</th>
              <th></th>
            </tr>
            <tr v-for="u in adminUsers" v-bind:key="u.userAccountId">
              <td>{{u.email}}</td>
              <td>{{u.name}}</td>
              <td class="text-center"><span v-if="!u.inCharge" class="icon">circle</span><span v-else class="icon">remove</span></td>
              <td class="text-center"><span v-if="u.inCharge" class="icon">circle</span><span v-else class="icon">remove</span></td>
              <td class="text-center">
                <button v-if="!u.inCharge" v-button:delete @click="deleteTestAdmin(u.userAccountId)" />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          管理者は登録されていません。
        </div>
      </div>
    </section>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";

export default {
  data() {
    return {
      site: {},
      adminUsers: [],
      userAccountId: null,
      error: null
    }
  },
  computed: {
    siteId: vm => vm.$route.query.siteId
  },
  mounted() {
    this.refreshSite();
    this.refreshTestAdmins();
  },
  methods: {
    refreshSite() {
      return Service.fetchSite(this.siteId).then(site => this.site = site);
    },
    refreshTestAdmins() {
      return Service.selectTestAdmins(this.siteId).then(adminUsers => {
        this.adminUsers = adminUsers;
      });
    },
    returnToList() {
      this.$move('./index', null, true);
    },
    deleteTestAdmin(userAccountId) {
      if (!confirm('削除してもよろしいですか？')) return;
      Service.deleteTestAdmin(this.site.siteId, userAccountId).then(() => {
        this.refreshTestAdmins();
      }).catch(error => this.error = error);
    },
  }
};
</script>
