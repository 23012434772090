<template>
  <default-layout>
    <div class="back-link">
      <button v-button:to-prev @click="returnToDetail"/>
    </div>
    <section v-if="examTerm">
      <h2 class="section-title"><span class="icon">edit_square_outline</span>アカウント登録メール送信</h2>
      <div class="table-responsive">
        <table class="table table-bordered" v-cols="[16, 17, 16, 17, 16, 18]">
          <tbody>
          <tr>
            <th>ID</th>
            <td class="text-center">{{examTerm.examTermId}}</td>
            <th>名前</th>
            <td class="text-left">{{examTerm.name}}</td>
            <th>試験問題コード</th>
            <td class="text-left">{{examTerm.examFormGroupCode}}</td>
          </tr>
          <tr>
            <th>実施期間</th>
            <td colspan="5">{{examTerm.startTime | datetime}} 〜 {{examTerm.endTime | datetime}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </section>
    <section v-if="userAccounts">
      <h2 class="section-title"><span class="icon">edit_square_outline</span>送信内容（未登録ユーザー宛）</h2>
      <div>
        <label>件名：</label>
        <input-text v-model="form.subjectForUnregisteredUser" />
      </div>
      <div>
        <label>本文：</label>
        <input-textarea class="mail-body" v-model="form.bodyForUnregisteredUser" />
      </div>
    </section>
    <section v-if="userAccounts">
      <h2 class="section-title"><span class="icon">edit_square_outline</span>送信内容（登録済みユーザー宛）</h2>
      <div>
        <label>件名：</label>
        <input-text v-model="form.subjectForRegisteredUser" />
      </div>
      <div>
        <label>本文：</label>
        <input-textarea class="mail-body" v-model="form.bodyForRegisteredUser" />
      </div>
    </section>
    <section v-if="userAccounts">
      <h2 class="section-title"><span class="icon">list</span>宛先</h2>
      <div class="table-responsive">
        <table class="table table-bordered" v-cols="[8, 40, 22, 15, 15]">
          <tbody>
          <tr>
            <th><input-checkbox v-model="checkAll" @input="toggleCheckAll"/></th>
            <th>メールアドレス</th>
            <th>名前</th>
            <th>未登録</th>
            <th>登録済</th>
          </tr>
          <tr v-for="u in userAccounts" :key="u.userAccountId">
            <td class="text-center"><input-checkbox v-model="u.checked"/></td>
            <td>{{u.email}}</td>
            <td>{{u.name}}</td>
            <td class="text-center"><span class="icon" v-if="!u.registered">circle</span><span class="icon" v-else>remove</span></td>
            <td class="text-center"><span class="icon" v-if="u.registered">circle</span><span class="icon" v-else>remove</span></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center">
        <button v-button:primary @click="sendMail">送信する</button>
      </div>
    </section>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import { announce } from "@/calico-vue/service";

export default {
  data() {
    return {
      checkAll: false,
      examTerm: null,
      userAccounts: null,
      form: {
        subjectForUnregisteredUser: '',
        bodyForUnregisteredUser: '',
        subjectForRegisteredUser: '',
        bodyForRegisteredUser: '',
      },
      error: null
    }
  },
  computed: {
    examTermId: vm => vm.$route.query.examTermId
  },
  created() {
    this.refreshExamTerm();
  },
  methods: {
    returnToDetail() {
      this.$move('detail', { examTermId: this.examTermId }, true);
    },
    refreshExamTerm() {
      Service.fetchExamTerm(this.examTermId).then(data => {
        this.examTerm = data.examTerm;
      }).catch(error => this.error = error);
      Service.selectTaUserAccounts(this.examTermId).then(data => {
        this.userAccounts = data;
        this.userAccounts.forEach(u => u.checked = true);
        this.checkAll = true;
      }).catch(error => this.error = error);
      Service.getMailTemplate().then(data => {
        this.form.subjectForUnregisteredUser = data.subjectForUnregisteredUser;
        this.form.bodyForUnregisteredUser = data.bodyForUnregisteredUser;
        this.form.subjectForRegisteredUser = data.subjectForRegisteredUser;
        this.form.bodyForRegisteredUser = data.bodyForRegisteredUser;
      }).catch(error => this.error = error);
    },
    toggleCheckAll() {
      this.userAccounts.forEach(u => u.checked = this.checkAll);
    },
    sendMail() {
      const mail = {
        examTermId: this.examTermId,
        subjectForUnregisteredUser: this.form.subjectForUnregisteredUser,
        bodyForUnregisteredUser: this.form.bodyForUnregisteredUser,
        subjectForRegisteredUser: this.form.subjectForRegisteredUser,
        bodyForRegisteredUser: this.form.bodyForRegisteredUser,
        userAccountIds: this.userAccounts.filter(u => u.checked).map(u => u.userAccountId)
      };
      Service.sendMail(mail).then(() => {
        announce.sent();
        this.returnToDetail();
      }).catch(error => this.error = error);
    }
  }
};
</script>

<style>
.mail-body textarea {
  height: 200px;
}
</style>
