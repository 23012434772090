<template>
  <default-layout>
    <section class="form-inline" v-if="form">
      <h2 class="section-title"><span class="icon">manage_search</span>検索条件</h2>
      <span class="form-inner">
        <span class="font-weight-bold">試験問題コード：</span>
        <input-text v-model="form.code" />
      </span>
      <div class="text-center pt-2">
        <button @click="sc.onSearch()" :disabled="processing" v-button:search />
      </div>
    </section>

    <section v-if="result">
      <h2 class="section-title"><span class="icon">view_list</span>試験問題一覧</h2>
      <div class="table-responsive">
        <div>
          <pager :search-context="sc" />
        </div>
        <table class="table table-bordered table-striped" v-cols="[20,70,10]">
          <tbody>
          <tr>
            <th>試験問題コード</th>
            <th>使われている試験</th>
            <th></th>
          </tr>
          <tr v-for="record in result.records" :key="record.examFormGroupId">
            <td class="text-center">{{record.code}}</td>
            <td><span v-for="t in record.examTerms" :key="t.examTermId" class="code-names">{{t.name}}</span></td>
            <td class="text-center"><button v-button:detail @click="$move('detail', { examFormGroupId: record.examFormGroupId }, $event)"></button></td>
          </tr>
          </tbody>
        </table>
        <div>
          <pager :search-context="sc" />
        </div>
      </div>
    </section>

    <section v-if="result">
      <div class="text-center">
        <button v-button:new @click="addExamForm"/>
      </div>
    </section>

    <modal ref="examFormModal" title="新規追加">
      <exam-form-modal @close="closeExamFormModal" />
    </modal>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import {SearchContext} from '@/calico-vue/service';
import ExamFormModal from "@/app/form/ExamFormModal.vue";

export default {
  components: {ExamFormModal},
  data() {
    return {
      sc: null,
      form: false,
      result: null,
      error: null
    }
  },
  created() {
    this.sc = SearchContext.forDefault(this, {
      api: Service,
      eager: true,
    }).init();
  },
  methods: {
    addExamForm() {
      this.$refs.examFormModal.open();
    },
    closeExamFormModal(refresh) {
      if (refresh) {
        this.sc.onSearch();
      }
      this.$refs.examFormModal.close();
    }
  }
}
</script>
<style scoped>
.code-names {
  margin-right: 10px;
}
</style>
