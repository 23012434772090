<template>
  <default-layout>
    <div class="back-link">
      <button v-button:to-list @click="returnToList"/>
    </div>
    <section v-if="examTerm && site">
      <h2 class="section-title"><span class="icon">edit_square</span>試験・実施教室</h2>
      <div class="table-responsive">
        <table class="table table-bordered" v-cols="[15, 20, 15, 30, 10, 10]">
          <tbody>
          <tr>
            <th>試験名</th>
            <td>{{examTerm.name}}</td>
            <th>実施期間</th>
            <td>{{examTerm.startTime | datetime}} 〜 {{examTerm.endTime | datetime}}</td>
            <th>採点</th>
            <td>{{examTerm.marked ? "済" : "未"}}</td>
          </tr>
          <tr>
            <th>教室コード</th>
            <td>{{site.code}}</td>
            <th>教室名</th>
            <td colspan="3">{{site.name}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center" v-if="examTerm.marked && examinees">
        <button @click="downloadSiteScoreReports" v-button:download>スコアレポートダウンロード（教室全員分）</button>
        <button @click="downloadSiteCertificates" v-button:download>合格証ダウンロード（教室全員分）</button>
      </div>
    </section>

    <section v-if="examinees !== null">
      <h2 class="section-title"><span class="icon">group</span>受検者一覧</h2>
      <div class="table-responsive" v-if="examinees">
        <table class="table table-bordered table-striped" v-cols="[18,15,13,10,13,10,10,10]">
          <tbody>
          <tr>
            <th>会員番号</th>
            <th>学年</th>
            <th>名前</th>
            <th>受検級</th>
            <th>受検日</th>
            <th>合否</th>
            <th>スコアレポート</th>
            <th>合格証</th>
          </tr>
          <tr v-for="e in examinees" v-bind:key="e.examineeId">
            <td class="text-center">{{e.examineeNumber}}</td>
            <td class="text-center">{{e.schoolGrade.name}}</td>
            <td class="text-left">{{e.familyName}} {{e.givenName}}</td>
            <td class="text-center">{{e.examGradeName || '未設定'}}</td>
            <td class="text-center">
              <span v-if="e.examDate">{{e.examDate | date}}</span>
              <span v-else>未受検</span>
            </td>
            <td class="text-center">{{e.passingGrade ? e.passingGrade.name : "−"}}</td>
            <td class="text-center"><button v-button:download @click="downloadScoreReport(e.examineeId)" v-if="e.passingGrade"/></td>
            <td class="text-center"><button v-button:download @click="downloadCertificate(e.examineeId)" v-if="e.passingGrade && e.passingGrade.id > 0"/></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        この教室には受検者はいません。
      </div>

    </section>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import {SearchContext} from "@/calico-vue/service";

export default {
  data() {
    return {
      examTerm: null,
      site: null,
      examinees: null,
      error: null
    }
  },
  computed: {
    examTermId: vm => vm.$route.query.examTermId,
    siteId: vm => vm.$route.query.siteId
  },
  created() {
    Service.fetchExamTerm(this.examTermId)
        .then(examTerm => {
          this.examTerm = examTerm;
          return Service.fetchSite(this.examTerm, this.siteId)
              .then(site => {
                this.site = site;
                return Service.selectExaminees(this.examTermId, this.siteId)
                    .then(examinees => this.examinees = examinees);
              });
        })
        .catch(error => this.error = error);
    this.sc = SearchContext.forDefault(this, {
      api: Service,
      eager: true,
    }).init();
  },
  methods: {
    returnToList() {
      this.$move('sites', { examTermId: this.examTermId, siteId: this.siteId }, true);
    },
    downloadSiteScoreReports() {
      window.open("/download/endpoint/org/exam/site/download_site_score_reports" +
          "?examTermId=" + this.examTermId + "&siteId=" + this.siteId, "_blank");
    },
    downloadSiteCertificates() {
      window.open("/download/endpoint/org/exam/site/download_site_certificates" +
          "?examTermId=" + this.examTermId + "&siteId=" + this.siteId, "_blank");
    },
    downloadScoreReport(examineeId) {
      window.open("/download/endpoint/org/exam/site/examinee/download_score_report" +
          "?examTermId=" + this.examTermId + "&siteId=" + this.siteId + "&examineeId=" + examineeId,
          "_blank");
    },
    downloadCertificate(examineeId) {
      window.open("/download/endpoint/org/exam/site/examinee/download_certificate" +
          "?examTermId=" + this.examTermId + "&siteId=" + this.siteId + "&examineeId=" + examineeId,
          "_blank");
    }
  }
};
</script>
