<template>
  <default-layout>
    <div class="back-link">
      <button v-button:to-list @click="returnToList"/>
    </div>
    <section class="form-inline" v-if="form">
      <h2 class="section-title"><span class="icon">manage_search</span>検索条件</h2>
      <div>
        <span class="form-inner">
          <span class="font-weight-bold">教室コード：</span>
          <input-text v-model="form.siteCode" />
        </span>
        <span class="form-inner">
          <span class="font-weight-bold">教室名：</span>
          <input-text v-model="form.siteName" />
        </span>
      </div>
      <div>
      <span class="form-inner">
        <span class="font-weight-bold ml-1">会員番号：</span>
        <input-text v-model="form.examineeNumber" />
      </span>
        <span class="form-inner">
        <span class="font-weight-bold ml-2">名前：</span>
        <input-text v-model="form.name" />
      </span>
      </div>
      <div class="text-center pt-2">
        <button @click="sc.onSearch()" :disabled="processing" v-button:search />
      </div>
    </section>

    <section v-if="result">
      <h2 class="section-title"><span class="icon">group</span>受検者一覧</h2>
      <div class="table-responsive">
        <table class="table table-bordered table-striped" v-cols="[15,20,15,20,5,15,20]">
          <tbody>
          <tr>
            <th>教室コード</th>
            <th>教室名</th>
            <th>会員番号</th>
            <th>名前</th>
            <th>性別</th>
            <th>学年</th>
            <th>生年月日</th>
          </tr>
          <tr v-for="e in result.records" v-bind:key="e.examineeId">
            <td class="text-center">{{e.siteCode}}</td>
            <td class="text-left">{{e.siteName}}</td>
            <td class="text-center">{{e.examineeNumber}}</td>
            <td class="text-left">{{e.familyName}} {{e.givenName}}</td>
            <td class="text-center">{{e.sex ? e.sex.name : ""}}</td>
            <td class="text-center">{{e.schoolGrade.name}}</td>
            <td class="text-center">{{e.dateOfBirth | date('YYYY/MM/DD')}}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </section>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import {SearchContext} from "@/calico-vue/service";
import {date} from "../../../calico-vue/filters";

export default {
  data() {
    return {
      form: null,
      result: null,
      error: null
    }
  },
  computed: {
    examTermId: vm => vm.$route.query.examTermId
  },
  created() {
    this.sc = SearchContext.forDefault(this, {
      api: {
        getSearchForm: () => Service.getExamineesSearchForm().then(form => {
          form.examTermId = this.examTermId
          return form;
        }),
        search: Service.examineesSearch
      },
      eager: true,
    }).init();
  },
  methods: {
    date,
    returnToList() {
      this.$move('index');
    },
  }
};
</script>
