<template>
  <form class="form-inline">
    <div class="table-responsive">
      <table class="table table-bordered" v-table:info v-cols="[40, 60]">
        <tbody>
          <tr>
            <th>問題データ</th>
            <td><input-file v-model="file" :error="$error('name')"/></td>
          </tr>
        </tbody>
      </table>
      <div class="text-center">
        <button v-button:cancel @click="close" />
        <button v-button:save @click="save" :disabled="processing"/>
      </div>
    </div>
  </form>
</template>

<script>
import {Service} from "./_resource";
import {announce} from "@/calico-vue/announce/announce";

export default {
  data() {
    return {
      file: null,
      processing: false
    };
  },
  props: [
    "examFormId"
  ],
  methods: {
    close() {
      this.$emit('close', false);
    },
    save() {
      this.processing = true;
      Service.uploadForm(this.examFormId, this.file).then(() => {
        announce.saved();
        this.$emit('close', true);
      }).catch(error => {
        this.error = error;
        this.processing = false;
      });
    }
  }
};
</script>
