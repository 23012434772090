<template>
  <default-layout>
    <section class="form-inline" v-if="form">
      <h2 class="section-title"><span class="icon">manage_search</span>検索条件</h2>
      <div class="search-cond-row">
        <span class="form-inner">
          <span class="font-weight-bold">団体コード：</span>
          <input-text v-model="form.organizationCode" />
        </span>
        <span class="form-inner">
          <span class="font-weight-bold">団体名：</span>
          <input-text v-model="form.organizationName" />
        </span>
      </div>
      <span class="form-inner">
        <span class="font-weight-bold">メールアドレス：</span>
        <input-text v-model="form.email" />
      </span>
      <span class="form-inner">
        <span class="font-weight-bold">名前：</span>
        <input-text v-model="form.name" />
      </span>
      <div class="text-center pt-2">
        <button @click="sc.onSearch()" :disabled="processing" v-button:search />
      </div>
    </section>

    <section v-if="result">
      <h2 class="section-title"><span class="icon">view_list</span>管理者一覧</h2>
      <div class="table-responsive">
        <div>
          <pager :search-context="sc" />
        </div>
        <table class="table table-bordered table-striped" v-cols="[40, 50, 10]">
          <tbody>
          <tr>
            <th>メールアドレス</th>
            <th>名前</th>
            <th></th>
          </tr>
          <tr v-for="record in result.records" :key="record.userAccountId">
            <td class="text-left">{{record.email}}</td>
            <td class="text-left">{{record.name}}</td>
            <td class="text-center"><button v-button:detail @click="$move('detail', { userAccountId: record.userAccountId }, $event)"></button></td>
          </tr>
          </tbody>
        </table>
        <div>
          <pager :search-context="sc" />
        </div>
      </div>
    </section>

    <modal ref="testAdminModal" title="新規追加">
      <test-admin-modal @close="closeTestAdminModal(refresh)" />
    </modal>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import {SearchContext} from '@/calico-vue/service';
import TestAdminModal from "./TestAdminModal";

export default {
  components: {TestAdminModal},
  data() {
    return {
      sc: null,
      form: false,
      result: null,
      error: null
    }
  },
  created() {
    this.sc = SearchContext.forDefault(this, {
      api: Service,
      eager: true,
    }).init();
  },
  methods: {
    addTestAdmin() {
      this.$refs.testAdminModal.open();
    },
    closeTestAdminModal(refresh) {
      if (refresh) {
        this.sc.onSearch();
      }
      this.$refs.testAdminModal.close();
    },
  }
}
</script>
