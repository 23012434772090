<template>
  <form v-form class="form-inline">
    <div class="table-responsive">
      <table class="table table-bordered" v-table:info v-cols="[20, 80]">
        <tbody>
          <tr v-if="form.examTermId">
            <th>試験ID</th>
            <td>{{form.examTermId}}</td>
          </tr>
          <tr>
            <th v-req>試験名</th>
            <td><input-text v-model="form.name" :error="$error('name')"/></td>
          </tr>
          <tr>
            <th v-req="!!examTermId"><div>試験問題</div><div>コード</div></th>
            <td><input-text v-model="form.examFormGroupCode" :error="$error('examFormGroupCode')"/></td>
          </tr>
          <tr>
            <th rowspan="2" v-req>実施期間</th>
            <td>
              開始日時：
              <span class="form-inner">
                <input-date v-model="form.startDate" :error="$error('startDate')"/>
              </span>
              <span class="form-inner">
                <input-time v-model="form.startTime" :error="$error('startTime')"/>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              終了日時：
              <span class="form-inner">
                <input-date v-model="form.endDate" :error="$error('endDate')"/>
              </span>
              <span class="form-inner">
                <input-time v-model="form.endTime" :error="$error('endTime')"/>
              </span>
            </td>
          </tr>
          <tr>
            <th><div>受験者</div><div>修正期限</div></th>
            <td>
              <span class="form-inner">
                <input-date v-model="form.correctionDeadlineDate" :error="$error('correctionDeadlineDate')"/>
              </span>
              <span class="form-inner">
                <input-time v-model="form.correctionDeadlineTime" :error="$error('correctionDeadlineTime')"/>
              </span>
            </td>
          </tr>
          <template v-if="isGakken">
            <tr>
              <th>採点日時</th>
              <td>
                <div v-if="form.marked">{{form.markedTime | datetime }}</div>
                <div v-else>-</div>
              </td>
            </tr>
            <tr>
              <th>結果公開</th>
              <td >
                <div v-if="form.marked">
                  <input-radios v-model="form.showResults" name="showResults" :options="showResultsOptions" :checkoffEnabled="false"/></div>
                <div v-else>-</div>
              </td>
            </tr>
            <tr>
              <th v-req>登録団体</th>
              <td>
                <input-radios v-model="form.organizationId" name="organizationId" :options="organizationOptions" :checkoffEnabled="false" :error="$error('organizationId')"/>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="text-center">
        <button v-button:cancel @click="close" />
        <button v-button:save @click="save" :disabled="processing"/>
      </div>
    </div>
  </form>
</template>

<script>
import {Service} from "./_resource";
import {announce} from "@/calico-vue/announce/announce";
import DateUtil from "@/calico-vue/misc/DateUtil";
import InputRadios from "@/calico-vue/form/components/InputRadios.vue";

export default {
  components: {InputRadios},
  data() {
    return {
      form: {
        examTermId: null,
        name: null,
        examFormGroupCode: null,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        correctionDeadlineDate: null,
        correctionDeadlineTime: null,
        organizationId: null
      },
      isGakken: process.env.VUE_APP_MODE_GAKKEN === 'true',
      organizations: null,
      error: null
    };
  },
  props: [
    "examTermId"
  ],
  created() {
    if (this.isGakken) {
      Service.getOrganizations().then(data =>{
        this.organizations = data.organizations;
      });
    }
    if (this.examTermId) {
      Service.fetchExamTerm(this.examTermId).then(data => {
        this.form = {
          examTermId: data.examTerm.examTermId,
          name: data.examTerm.name,
          examFormGroupCode: data.examTerm.examFormGroupCode,
          startDate: DateUtil.format(data.examTerm.startTime, 'YYYY-MM-DD'),
          startTime: DateUtil.format(data.examTerm.startTime, 'HH:mm'),
          endDate: DateUtil.format(data.examTerm.endTime, 'YYYY-MM-DD'),
          endTime: DateUtil.format(data.examTerm.endTime, 'HH:mm'),
          correctionDeadlineDate: data.examTerm.correctionDeadline ? DateUtil.format(data.examTerm.correctionDeadline, 'YYYY-MM-DD') : null,
          correctionDeadlineTime: data.examTerm.correctionDeadline ? DateUtil.format(data.examTerm.correctionDeadline, 'HH:mm') : null,
          showResults: data.examTerm.showResults,
          marked: data.examTerm.marked,
          markedTime: data.examTerm.markedTime,
          organizationId: String(data.organizationId),
          organizationRequired: this.isGakken
        };
      });
    } else {
      this.form = {
        examTermId: null,
        name: null,
        examFormGroupCode: null,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        correctionDeadlineDate: null,
        correctionDeadlineTime: null,
        showResult: false,
        markedTime: null,
        organizationId: null,
        organizationRequired: this.isGakken
      };
    }
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
    save() {
      if (this.examTermId) {
        Service.updateExamTerm(this.form).then(() => {
          announce.saved();
          this.$emit('close', true);
        }).catch(error => {
          this.error = error;
        });
      } else {
        Service.addExamTerm(this.form).then(data => {
          announce.saved();
          this.$move('detail', {examTermId: data.examTermId});
        }).catch(error => {
          this.error = error;
        });
      }
    }
  },
  computed: {
    showResultsOptions() {
      return [
        {id: false, name: "非公開"},
        {id: true, name: "結果公開"},
      ];
    },
    organizationOptions() {
      const options = [];
      this.organizations?.forEach(g => options.push({ id: String(g.organizationId), name: g.name }));
      return options;
    }
  }
};
</script>
