<template>
  <default-layout>
    <section v-if="result">
      <h2 class="section-title"><span class="icon"></span>お知らせ一覧</h2>
      <div class="table-responsive">
        <div>
          <pager :search-context="sc" />
        </div>
        <table class="table table-bordered table-striped" v-cols="[10,20,40,20,10]">
          <tbody>
          <tr>
            <th>ID</th>
            <th>件名</th>
            <th>表示期間</th>
          </tr>
          <tr v-for="record in result.records" :key="record.informationId">
            <td class="text-right">{{record.informationId}}</td>
            <td class="text-left">{{record.subject}}</td>
            <td class="text-center">{{record.startTime | datetime}}</td>
            <td class="text-center">{{record.endTime | datetime}}</td>
          </tr>
          </tbody>
        </table>
        <div>
          <pager :search-context="sc" />
        </div>
      </div>
    </section>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import {SearchContext} from '@/calico-vue/service';

export default {
  data () {
    return {
      sc: null,
      form: false,
      result: null,
      error: null,
    }
  },
  created() {
    this.sc = SearchContext.forDefault(this, {
      api: Service,
      eager: true,
    }).init();
  }
}
</script>
