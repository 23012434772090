<template>
  <form v-form class="form-inline">
    <div class="table-responsive" v-if="user">
      <table class="table table-bordered" v-table:info v-cols="[40, 60]">
        <tbody>
        <tr>
          <th v-req="!userAccountId">メールアドレス</th>
          <td v-if="userAccountId">{{user.email}}</td>
          <td v-else><input-text v-model="user.email" :error="$error('email')"/></td>
        </tr>
        <tr>
          <th v-req>名前</th>
          <td><input-text v-model="user.name" :error="$error('name')"/></td>
        </tr>
        </tbody>
      </table>
      <div class="text-center">
        <button v-button:cancel @click="close" />
        <button v-button:save @click="save" :disabled="processing"/>
      </div>
    </div>
  </form>
</template>

<script>
import {Service} from "./_resource";
import {announce} from "@/calico-vue/announce/announce";

export default {
  data() {
    return {
      siteId: null,
      user: null,
      error: null
    };
  },
  props: [
    "userAccountId"
  ],
  mounted() {
    if (this.userAccountId) {
      Service.fetchTestAdmin(this.userAccountId).then(user => {
        this.user = user;
      });
    } else {
      this.user = {};
    }
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
    save() {
      if (this.userAccountId) {
        Service.updateTestAdmin(this.userAccountId, this.user.name).then(() => {
          announce.saved();
          this.$emit('close', true);
        }).catch(error => {
          this.error = error;
        });
      } else {
        Service.addTestAdmin(this.user.email, this.user.name).then(data => {
          announce.saved();
          this.$move('detail', {userAccountId: data.userAccountId});
        }).catch(error => {
          this.error = error;
        });
      }
    }
  }
};
</script>
