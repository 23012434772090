<template>
  <default-layout>
  </default-layout>
</template>

<script>
import {Service} from './_resource';

export default {
  mounted() {
    Service.isOrgAdmin().then(result => {
      console.log(result);
      if (result.isOrgAdmin) {
        this.$move('/org/exam');
      } else {
        this.$move('/exam');
      }
    })
  }
}
</script>
