<template>
  <default-layout>
    <section v-if="result">
      <h2 class="section-title"><span class="icon">view_list</span>試験一覧</h2>
      <div class="table-responsive">
        <div>
          <pager :search-context="sc" />
        </div>
        <table class="table table-bordered table-striped" v-cols="[20,50,15,15]">
          <tbody>
          <tr>
            <th>試験名</th>
            <th>実施期間</th>
            <th></th>
            <th></th>
          </tr>
          <tr v-for="record in result.records" :key="record.examTermId">
            <td class="text-left">{{record.name}}</td>
            <td class="text-center">{{record.startTime | datetime}} 〜 {{record.endTime | datetime}}</td>
            <td class="text-center"><button v-button:primary @click="openUploadModal(record.examTermId)">登録</button></td>
            <td class="text-center"><button v-button:detail @click="$move('examinees', { examTermId: record.examTermId })"></button></td>
          </tr>
          </tbody>
        </table>
        <div>
          <pager :search-context="sc" />
        </div>
      </div>
    </section>
    <modal ref="uploadModal" title="受検者一覧登録">
      <upload-modal :exam-term-id="formExamTermId" @close="closeUploadModal"/>
    </modal>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import {SearchContext} from '@/calico-vue/service';
import UploadModal from "./UploadModal";

export default {
  components: {UploadModal},
  data () {
    return {
      result: null,
      formExamTermId: null,
      error: null,
    }
  },
  computed: {
    examTermId: vm => vm.$route.query.examTermId
  },
  created() {
    this.sc = SearchContext.forDefault(this, {
      api: Service,
      eager: true,
    }).init();
  },
  methods: {
    openUploadModal(examTermId) {
      this.formExamTermId = examTermId;
      this.$refs.uploadModal.open();
    },
    closeUploadModal() {
      this.$refs.uploadModal.close();
    }
  }
}
</script>
