<template>
  <default-layout>
    <div class="back-link">
      <button v-button:to-list @click="returnToList"/>
    </div>
    <section>
      <h2 class="section-title"><span class="icon">corporate_fare</span>教室</h2>
      <div class="row">
        <div class="table-responsive">
          <table class="table table-bordered" v-table:info v-cols="[30,20,20,30]">
            <tbody>
            <tr>
              <th>教室コード</th>
              <td>{{site.siteCode}}</td>
              <th>教室名</th>
              <td>{{site.siteName}}</td>
            </tr>
            <tr>
              <th>教室担当者登録パスワード</th>
              <td colspan="3">{{site.registrationPassword}}</td>
            </tr>
            <tr>
              <th>教室担当者</th>
              <td colspan="5">
                <span v-if="site.taName">{{site.taName}} &lt;{{site.taEmail}}&gt;</span>
                <span v-else>未登録</span>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="text-center">
            <button v-button:edit @click="editSite" />
            <button v-button:delete @click="deleteSite" v-if="site.deletable"/>
          </div>
        </div>
      </div>
    </section>
    <section>
      <h2 class="section-title"><span class="icon">badge</span>管理者</h2>
      <div class="row">
        <div v-if="adminUsers.length > 0" class="table-responsive">
          <table class="table table-bordered table-striped" v-table:info v-cols="[30,25,15,15,15]">
            <tbody>
            <tr>
              <th>メールアドレス</th>
              <th>名前</th>
              <th>管理者</th>
              <th>教室担当者</th>
              <th></th>
            </tr>
            <tr v-for="u in adminUsers" v-bind:key="u.userAccountId">
              <td>{{u.email}}</td>
              <td>{{u.name}}</td>
              <td class="text-center"><span v-if="!u.inCharge" class="icon">circle</span><span v-else class="icon">remove</span></td>
              <td class="text-center"><span v-if="u.inCharge" class="icon">circle</span><span v-else class="icon">remove</span></td>
              <td class="text-center">
                <button v-if="!u.inCharge" v-button:delete @click="deleteTestAdmin(u.userAccountId)" />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          管理者は登録されていません。
        </div>
      </div>
    </section>

    <modal ref="siteModal" title="教室情報編集">
      <site-modal :siteId="site.siteId" @close="closeSiteModal" />
    </modal>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";
import SiteModal from "./SiteModal";

export default {
  components: {SiteModal},
  data() {
    return {
      site: {},
      adminUsers: [],
      userAccountId: null,
      error: null
    }
  },
  computed: {
    siteId: vm => vm.$route.query.siteId
  },
  mounted() {
    this.refreshSite();
    this.refreshTestAdmins();
  },
  methods: {
    refreshSite() {
      return Service.fetchSite(this.siteId).then(site => this.site = site);
    },
    refreshTestAdmins() {
      return Service.selectTestAdmins(this.siteId).then(adminUsers => {
        this.adminUsers = adminUsers;
      });
    },
    editSite() {
      this.$refs.siteModal.open();
    },
    closeSiteModal(refresh) {
      if (refresh) {
        this.refreshSite().then(() => this.$refs.siteModal.close());
      } else {
        this.$refs.siteModal.close();
      }
    },
    returnToList() {
      this.$move('./index', null, true);
    },
    deleteTestAdmin(userAccountId) {
      if (!confirm('削除してもよろしいですか？')) return;
      Service.deleteTestAdmin(this.site.siteId, userAccountId).then(() => {
        this.refreshTestAdmins();
      }).catch(error => this.error = error);
    },
    deleteSite() {
      if (!confirm('削除してもよろしいですか？')) return;
      Service.deleteSite(this.site.siteId).then(() => {
        this.returnToList();
      }).catch(error => this.error = error);
    }
  }
};
</script>
