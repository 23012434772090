<template>
  <default-layout>
    <section class="form-inline" v-if="form">
      <h2 class="section-title"><span class="icon">manage_search</span>検索条件</h2>
      <div class="search-cond-row">
        <span class="form-inner">
          <span class="font-weight-bold">団体コード：</span>
          <input-text v-model="form.organizationCode" />
        </span>
        <span class="form-inner">
          <span class="font-weight-bold">団体名：</span>
          <input-text v-model="form.organizationName" />
        </span>
      </div>
      <div class="search-cond-row">
        <span class="form-inner">
          <span class="font-weight-bold">会場コード：</span>
          <input-text v-model="form.code" />
        </span>
        <span class="form-inner">
          <span class="font-weight-bold">会場名：</span>
          <input-text v-model="form.name" />
        </span>
      </div>
      <div class="text-center pt-2">
        <button @click="sc.onSearch()" :disabled="processing" v-button:search />
      </div>
    </section>

    <section v-if="result">
      <h2 class="section-title"><span class="icon">view_list</span>会場一覧</h2>
      <div class="table-responsive">
        <div>
          <pager :search-context="sc" />
        </div>
        <table class="table table-bordered table-striped" v-cols="[12,32,12,32,12]">
          <tbody>
            <tr>
              <th>団体コード</th>
              <th>団体名</th>
              <th>会場コード</th>
              <th>会場名</th>
              <th></th>
            </tr>
            <tr v-for="record in result.records" :key="record.siteId">
              <td class="text-center">{{record.organizationCode}}</td>
              <td class="text-left">{{record.organizationName}}</td>
              <td class="text-center">{{record.code}}</td>
              <td class="text-left">{{record.name}}</td>
              <td class="text-center"><button v-button:detail @click="$move('detail', { siteId: record.siteId }, $event)"></button></td>
            </tr>
          </tbody>
        </table>
        <div>
          <pager :search-context="sc" />
        </div>
      </div>
    </section>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import {SearchContext} from '@/calico-vue/service';

export default {
  data() {
    return {
      sc: null,
      form: false,
      result: null,
      error: null
    }
  },
  created() {
    this.sc = SearchContext.forDefault(this, {
      api: Service,
      eager: true,
    }).init();
  }
}
</script>

<style>
.search-cond-row {
  margin-bottom: 10px;
}
</style>
