<template>
  <default-layout>
    <section v-if="result">
      <h2 class="section-title"><span class="icon">view_list</span>試験実施一覧</h2>
      <div class="table-responsive">
        <div>
          <pager :search-context="sc" />
        </div>
        <table class="table table-bordered table-striped" v-cols="[8,15,40,20,10,7]">
          <tbody>
          <tr>
            <th>試験ID</th>
            <th>試験名</th>
            <th>実施期間</th>
            <th>採点日時</th>
            <th>結果公開</th>
            <th></th>
          </tr>
          <tr v-for="record in result.records" :key="record.examTermId">
            <td class="text-right">{{record.examTermId}}</td>
            <td class="text-left">{{record.name}}</td>
            <td class="text-center">{{record.startTime | datetime}} 〜 {{record.endTime | datetime}}</td>
            <td class="text-center">{{record.markedTime | datetime}}</td>
            <td class="text-center">{{record.showResults ? "公開済" : ""}}</td>
            <td class="text-center"><button v-button:detail @click="$move('detail', { examTermId: record.examTermId })"></button></td>
          </tr>
          </tbody>
        </table>
        <div>
          <pager :search-context="sc" />
        </div>
      </div>
    </section>

    <section v-if="result">
      <div class="text-center">
        <button v-button:new @click="addExamTerm"/>
      </div>
    </section>

    <modal ref="examTermModal" title="新規追加">
      <exam-term-modal @close="closeExamTermModal" />
    </modal>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import {SearchContext} from '@/calico-vue/service';
import ExamTermModal from './ExamTermModal';

export default {
  components: {ExamTermModal},
  data () {
    return {
      sc: null,
      form: false,
      result: null,
      error: null,
    }
  },
  created() {
    this.sc = SearchContext.forDefault(this, {
      api: Service,
      eager: true,
    }).init();
  },
  methods: {
    addExamTerm() {
      this.$refs.examTermModal.open();
    },
    closeExamTermModal(refresh) {
      if (refresh) {
        this.sc.onSearch();
      }
      this.$refs.examTermModal.close();
    },
  }
}
</script>
